import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import PageLayout from '../components/layouts/PageLayout'

export default () => {
    useEffect(() => {
        navigate('/patients-and-families', { replace: true })
    })

    return (
        <PageLayout className="guideline-list">
            <Helmet title="Starship" />
        </PageLayout>
    )
}
